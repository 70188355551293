
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {TABLE_FOOTER_OPTIONS} from '@/misc/TableFooterOptions';
import ContainerType from "@/models/ContainerType.model";
import {ContainerTypeStatus} from "@/enum/ContainerTypeStatus";

@Component
export default class ContainerTypeRequestListComponent extends Vue {
  /**
   * flag that handles the loading state of the table
   */
  @Prop({default: false})
  private isLoading!: boolean;

  /**
   * string to query the table
   */
  @Prop({default: ''})
  private search!: string;

  /**
   * flag to identify which set of items should be shown (requested or handled)
   */
  @Prop({default: false})
  private showProvedItems!: boolean;

  /**
   * all types that should be shown inside the pending types tab
   */
  @Prop({required: true})
  private pendingContainerTypes!: ContainerType[];

  /**
   * all types that should be shown inside the handled types tab
   */
  @Prop({required: true})
  private handledContainerTypes!: ContainerType[];

  /**
   * callback when a type was accepted
   */
  @Prop({required: true})
  private onItemAccepted!: (item: ContainerType) => Promise<void>;

  /**
   * callback when a type was denied
   */
  @Prop({required: true})
  private onItemDenied!: (item: ContainerType) => Promise<void>;

  /**
   * callback when the tab has changed inside the list itself
   */
  @Prop({required: true})
  private onProvedItemsStatusChanged!: (status: boolean) => void;

  /**
   * callback when a route change is requested by the list
   */
  @Prop({required: true})
  private onRouteChangeTriggered!: (id: string | null) => void;

  /**
   * list of expanded elements, just holds one element
   */
  private expanded: ContainerType = new ContainerType();

  /**
   * returns the table items based 'showProvedItems' flag
   * @private
   */
  private get tableItems() {
    return this.showProvedItems ? this.handledContainerTypes: this.pendingContainerTypes;
  }

  /**
   * watches for the loading state, when the container types are loaded, checks if a request id is used inside the route
   * and tries to find the container based on the id
   */
  @Watch('isLoading', {deep: true, immediate: true})
  private onLoadingStateChanged() {
    // checks if the table is loading
    if(this.isLoading) return;

    // tries to fetch the request id from the route
    const requestId = this.$route.params.containerTypeId;
    if(!requestId) return;

    // tries to find the container type in the lists based on the requestId
    const pendingItem = this.pendingContainerTypes.find((c) => c.id === requestId);
    const handledItem = this.handledContainerTypes.find((c) => c.id === requestId);

    // sets the expanded item to be the found item
    const item = pendingItem ?? handledItem;
    if(!item) return;
    this.expanded = item;

    // checks if the containerType was found inside the handled list and opens the handled container type list by
    // default to show the expanded item right from the start
    if(handledItem) {
      this.onProvedItemsStatusChanged(true);
    }
  }

  /**
   * headers of the table
   * @private
   */
  private headers = [
    {text: this.$t('GENERAL.RESTAURANT_NAME'), align: 'start', sortable: true, value: 'company.name', formatter: this.getSafeValue},
    {text: this.$t('GENERAL.ADDRESS'), align: 'start', sortable: true, value: 'company.address', formatter: this.fullAddress},
    {text: this.$t('GENERAL.PHONE'), align: 'start', sortable: true, value: 'company.contact.phone', formatter: this.getSafeValue},
    {text: this.$t('CONTAINERS.NAME'), align: 'start', sortable: true, value: 'name', formatter: this.getSafeValue},
    {text: this.$t('CONTAINERS.BONUS_RATE'), align: 'start', sortable: true, value: 'lastPenaltyRate', formatter: this.translateToMoney},
    {text: this.showProvedItems ? this.$t('GENERAL.STATUS') : this.$t('CONTAINERS.ACTIONS'), align: 'start', sortable: false, value: 'actions'}
  ];

  /**
   * returns if the container type has accepted as status
   * @param item
   * @private
   */
  private isAccepted(item: ContainerType): boolean {
    return item.status === ContainerTypeStatus.ACCEPTED;
  }

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  /**
   * translates the value to be shown as currency
   * @param value
   * @private
   */
  private translateToMoney(value: number) {
    return this.$formatCurrency(value);
  }

  /**
   * is called when a row is clicked, pushes or removes the item from the list of expanded table items
   */
  private onRowClicked(value: ContainerType): void {
    if(this.expanded.id === value.id) {
      this.expanded = new ContainerType();
      this.onRouteChangeTriggered(null);
      return;
    }

    this.expanded = value;
    this.onRouteChangeTriggered(value.id!);
  }

  /**
   * returns if the row is the one that should be expanded
   */
  private isExpandedRow(id: string) {
    if(!this.expanded) return false;
    return this.expanded.id === id;
  }

  /**
   * is called when an item was accepted, calls callback function of the parent
   * @param item
   * @private
   */
  private async onAcceptPressed(item: ContainerType): Promise<void> {
    await this.onItemAccepted(item);
  }

  /**
   * is called when an item was denied, calls callback function of the parent
   * @param item
   * @private
   */
  private async onDenyPressed(item: ContainerType): Promise<void> {
    await this.onItemDenied(item);
  }

  /**
   * returns safe value, if value can't be found, returns placeholder value
   * @param value
   */
  public getSafeValue(value: any) {
    return value ?? '-';
  }

  /**
   * address of the items company
   * @param item
   * @private
   */
  private fullAddress(address: any) {
    // gets address of the item, if address is not available, returns placeholder
    if(!address) return '-';

    // returns address in address format
    return this.$t('FORMATTER.ADDRESS_FORMATTER', {street: address.street, houseNo: address.houseNo,
      zip: address.zip, city: address.city});
  }
}
